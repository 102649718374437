@import "../../../assets/scss/variables.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    font-size: 1.2rem !important;
    bottom: 1rem;
    right: 2rem;
    font-weight: 550;
    z-index: 1;

    @media only screen and (max-width: $container-md-min-width) {
        bottom: 3.5rem;
    }
}
