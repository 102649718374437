@import "../../../assets/scss/variables.scss";

.parentContainer {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    &:before {
        background-image: linear-gradient(to bottom right, $white, $white-fade);
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }

    .childContainer {
        display: flex;
        flex-direction: column;
        z-index: 1;
        width: 100%;

        @media only screen and (max-width: $container-md-min-width) {
            flex-direction: column-reverse;
        }

        .nav {
            position: fixed;
        }

        .parentContent {
            display: flex;
            margin-top: 3rem;
        }

        .content {
            display: flex;
            flex-direction: column;
            background: $white;
            padding: 2rem 2rem 2rem 0;

            .itemContainer {
                width: 100%;
                min-height: 3.5rem;
                height: 3.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
            }
        }
    }
}

.headingContainer {
    width: 100%;
    min-height: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: $white;
    white-space: nowrap;
    color: #ababab;
    position: sticky;
    top: 3rem;
    z-index: 1;
    align-self: stretch;
}

.fixture {
    display: flex;
    align-items: center;
    min-width: 14rem;
    width: 15rem;
    border-bottom: 1px solid #efefef;
    height: 100%;
    position: sticky;
    left: 0;
}

.round {
    min-width: 7rem;
    width: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #efefef;
    height: 100%;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 7rem;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    height: 100%;
}

.user {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 7.5rem;
    width: 7.5rem;
    word-wrap: break-word;
    white-space: normal;
    text-align: center;
    height: 100%;
    border-bottom: 1px solid #efefef;

    .userItem {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50%;
        border-right: 1px solid #efefef;
    }

    .score {
        margin-left: 0.2rem;
    }
}

.tracks {
    display: flex;
}

.entry {
    display: flex;
    align-items: center;
    height: 3.5rem;
    background: $white;
}

.sticky {
    background: $white;
    display: flex;
    height: 100%;
    position: sticky;
    left: 0;
    padding-left: 2rem;
}
