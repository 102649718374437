@import "../../../assets/scss/variables.scss";
@import "~@sbgsportssoftware/button/src/button.module.scss";

$btn-templates: (
    "primary": #6ca056
);

@each $btn_name, $color_value in $btn-templates {
    @include make-button($btn_name, $color_value);
    @include make-outline-button($btn_name, $color_value);
}
