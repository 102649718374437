@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,800|Raleway&display=swap");
@import "./assets/scss/variables.scss";

html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom right, $background, $background-fade);
    color: $font-color;
    font-family: $font;
    font-size: $font-size-default;
    font-weight: $font-weight-md;
    line-height: $line-height-lg;
    margin: 0;
}

*,
::after,
::before {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

.m-0 {
    margin: 0 !important;
}
