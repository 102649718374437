@import "../../assets/scss/variables.scss";

.container {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: $soft-shadow;
    min-height: 0;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $container-md-min-width) {
        flex: 1;
    }

    .title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }

    .heading {
        width: 100%;
        min-height: 3.5rem;
        height: 3.5rem;
        max-width: 1300px;
        padding: 1rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid #efefef;
        white-space: nowrap;
        color: #ababab;
    }

    .user {
        text-transform: capitalize;
        display: flex;
        width: 50%;
    }

    .ranking {
        display: flex;
        justify-content: center;
        width: 25%;
    }

    .score {
        display: flex;
        justify-content: center;
        width: 25%;
    }

    .button {
        margin-top: auto;
        width: 100%;
    }

    .itemContainer {
        width: 100%;
        min-height: 3.75rem;
        height: 3.75rem;
        max-width: 1300px;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #efefef;
    }
}
