/* Colours */

$background: #f8f8f8;
$background-fade: #f8f8f8;
$white: #ffffff;
$white-fade: #ffffff;
$white-off: #f7fdee;
$white-off-fade: #f7fdee;
$green: #6ca056;
$green-fade: #6ca056;
$dark-green: #699457;
$dark-green-fade: #3b9282;
$dark-grey: #3a3a3a;
$dark-grey-fade: #3a3a3a;
$light-black: #0000000a;
$black: #000;
$black-fade: #0e0e0e;

$font-color: #3a3a3a;
$font-color-soft: #ababab;
$placeholder: #757575;
$selected: #4285f4;
$warning: #ce7858;
$error: #d56035;
$loading: hsla(217, 89%, 61%, 0.65);

$nav-hover: #499d8e;
$nav-selected: #dfdede;

/* Padding, Margin, Border, Line Height */
$line-height-lg: 1.5 !default;
$line-height-sm: 1 !default;

/* Font */
$font-weight-md: 400;
$font-weight-lg: 600;
$font-size-sm: 0.81rem;
$font-size-default: 1rem;
$font: "Montserrat", "Raleway", sans-serif;

$font-size-title: 5.25rem;
$font-size-subtitle: 1.5rem;
$font-size-heading: 1.25rem;

/* Border Radius */
$border-radius-default: 0.125rem !default;

/* Shadows */

$shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;
$soft-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04), 0 2px 10px 0 rgba(0, 0, 0, 0.04) !default;
$shadow-hover: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !default;

/* Page */
$container-sm-min-width: 344px;
$container-md-min-width: 768px;
$container-lg-min-width: 992px;

/* Login  */

$login-size: 45vw;
$login-size-font: 10vw;

/* Fixture list columns */
$fixture-column-date-width: 20%;
$fixture-column-fixture-width: 60%;
$fixture-column-state-width: 20%;

/* Player */
$player-side-bar-max-width: 29rem;
$player-container-top-padding: 3rem;
$player-description-height: 3rem;

/* Admin */
$admin: $green;
$customer-admin: #4285f4;
$support: #ce7858;

/* States */
$available: $green;
$markup: #f9c842;
$waiting: #516375;
$cancelled: #c59f38;
$postponed: #e540ff;
