@import "../../assets/scss/variables.scss";

.container {
    width: 35%;
    height: 87%;
    padding: 1rem;
    margin-right: 4.75rem;
    background: $white-off;

    align-self: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;

    .title {
        line-height: 1;
        text-align: left;
        margin-bottom: auto;
        width: 100%;
    }

    .inputGroup {
        width: 100%;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            box-shadow: 0 0 0 30px $white-off inset !important;
            -webkit-box-shadow: 0 0 0 30px $white-off inset !important;
        }
    }

    .submit {
        width: 100%;
    }

    .forgotPassword {
        width: 100%;
        padding: 0 1.5rem;
        margin: 0;
    }

    @media only screen and (max-width: $container-md-min-width) {
        width: 85%;
        height: 60%;
        max-width: 310px;
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;

        .title {
            text-align: center;
        }
    }
}
