@import "../../../assets/scss/variables.scss";

.parentContainer {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    &:before {
        background-image: linear-gradient(to bottom right, $white, $white-fade);
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }

    .childContainer {
        flex: auto;
        display: flex;
        flex-direction: column;
        z-index: 1;
        width: 100%;
        height: 100%;

        @media only screen and (max-width: $container-md-min-width) {
            flex-direction: column-reverse;
        }

        .content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            min-height: 0;
            height: 100%;

            .addFixtureContainer {
                display: flex;
                flex-direction: column;
                width: 20rem;
                margin: 2rem;
            }

            .addResultContainer {
                display: flex;
                flex-direction: column;
                width: 20rem;
                margin: 2rem;
            }
        }
    }
}
