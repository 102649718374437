@import "../../assets/scss/variables.scss";

.modal {
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 1.5rem;

    @media only screen and (max-width: $container-md-min-width) {
        position: absolute;
        inset: 5px;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
    }
}

.parentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .childContainer {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1 0;

        .title {
            font-size: 4.25rem;
            margin: 1rem;

            @media only screen and (max-width: $container-md-min-width) {
                font-size: 1.75rem;
            }
        }

        .heading {
            width: 100%;
            min-height: 3.5rem;
            height: 3.5rem;
            max-width: 1300px;
            padding: 1rem;
            display: flex;
            align-items: center;
            flex-direction: row;
            border-bottom: 1px solid #efefef;
            white-space: nowrap;
            color: #ababab;
        }

        .fixture {
            display: flex;
            width: 40%;
        }

        .prediction {
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
        }

        .info {
            padding: 0 1rem 1rem 1rem;
            flex: 1 0 auto;
        }

        .container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-bottom: 1px solid #efefef;
            padding: 1rem;
            min-height: 3.75rem;
            flex: 1 0 auto;
            max-width: 1300px;
        }

        .innerContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .inputContainer {
                display: flex;
                flex: 1 1 auto;

                &.disabled {
                    opacity: 0.5;
                }

                .input {
                    margin-bottom: 0 !important;
                    max-width: 7.5rem;
                }

                .error {
                    display: none;
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        min-height: 3rem;
        padding-top: 1.5rem;

        .button {
            min-width: 8rem;
        }
    }
}

.penaltySelection {
    display: flex;
    align-items: center;
    height: 5rem;
}

.buttonGroup {
    display: flex;

    .button {
        min-width: 7.5rem;
        width: 7.5rem;
        margin: 0;

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.penaltyWinner {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    margin-top: 1rem;
    font-size: 0.8rem;
    color: #adadad;
}
