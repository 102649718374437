@import "../../assets/scss/variables.scss";

.container {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: $soft-shadow;
    min-height: 0;
    margin-left: auto;
    display: flex;
    flex-direction: column;

    .titleContainer {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        @media only screen and (max-width: $container-md-min-width) {
            flex-direction: column;
        }
    }

    .title {
        font-size: 1.2rem;
    }

    .buttonGroup {
        display: flex;
        justify-content: center;
        margin-left: auto;

        @media only screen and (max-width: $container-md-min-width) {
            margin-top: 1rem;
            margin-left: 0;
        }
    }

    .setJokerButton {
        width: 8rem;
    }

    .setPredictionsButton {
        height: 3rem;
        width: 9.5rem;
    }

    .heading {
        width: 100%;
        min-height: 3.5rem;
        height: 3.5rem;
        max-width: 1300px;
        padding: 1rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid #efefef;
        white-space: nowrap;
        color: #ababab;

        @media only screen and (max-width: $container-md-min-width) {
            padding: 0.5rem;
        }
    }

    .fixture {
        display: flex;
        width: 25%;

        @media only screen and (max-width: $container-md-min-width) {
            width: 45%;
        }
    }

    .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;

        @media only screen and (max-width: $container-md-min-width) {
            display: none;
        }
    }

    .prediction {
        display: flex;
        justify-content: center;
        width: 20%;

        @media only screen and (max-width: $container-md-min-width) {
            width: 27.5%;
        }
    }

    .result {
        display: flex;
        justify-content: center;
        width: 20%;

        @media only screen and (max-width: $container-md-min-width) {
            display: none;
        }
    }

    .score {
        display: flex;
        justify-content: center;
        width: 20%;

        @media only screen and (max-width: $container-md-min-width) {
            width: 25%;
        }
    }

    .itemContainer {
        width: 100%;
        min-height: 3.75rem;
        height: 3.75rem;
        max-width: 1300px;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #efefef;

        @media only screen and (max-width: $container-md-min-width) {
            padding: 0.5rem;
        }
    }
}
