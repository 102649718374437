@import "../../assets/scss/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    width: 32%;
    padding: 1rem;
    border-radius: 0.5rem;
    min-height: 0;
    box-shadow: $soft-shadow;

    .labelContainer {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 0.5rem;
            color: $dark-green;
        }

        .label {
            display: flex;
            justify-content: space-around;
            font-size: 1rem;
        }
    }

    .result {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        font-size: 2.5rem;
        font-weight: 550;
        color: $dark-green;
    }
}
