@function set-color($color) {
    @if (lightness($color) > 80) {
        @return $black;
    } @else {
        @return $white;
    }
}

@mixin hover-focus-active {
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}

@mixin overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
