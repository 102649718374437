@import "../../../assets/scss/variables.scss";

.parentContainer {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    &:before {
        background-image: linear-gradient(to bottom right, $white, $white-fade);
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }

    .childContainer {
        display: flex;
        flex-direction: column;
        z-index: 1;
        width: 100%;

        @media only screen and (min-width: $container-md-min-width) {
            overflow-x: hidden;
            overflow-y: auto;
        }

        @media only screen and (max-width: $container-md-min-width) {
            flex-direction: column-reverse;
        }

        .content {
            display: flex;
            padding: 2rem;

            @media only screen and (max-width: $container-md-min-width) {
                flex-direction: column;
                align-items: center;
                overflow-x: hidden;
                overflow-y: auto;
            }

            .leftContent {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                width: 100%;

                @media only screen and (max-width: $container-md-min-width) {
                    flex: 1 0 auto;
                }
            }

            .predictionsLeaderboard {
                width: 30%;

                @media only screen and (max-width: $container-md-min-width) {
                    width: 100%;
                    margin: 2rem;
                }
            }

            .headingContainer {
                display: flex;
                justify-content: space-between;
                padding: 0 1.5rem 0 1.5rem;
                width: 100%;
                height: 12.5rem;

                @media only screen and (max-width: $container-md-min-width) {
                    padding: 1.5rem 0 0 0;
                }

                .predictionHeading {
                    @media only screen and (max-width: $container-md-min-width) {
                        width: 48%;
                    }
                }

                .predictionHeading:last-of-type {
                    @media only screen and (max-width: $container-md-min-width) {
                        display: none;
                    }
                }
            }

            .userPredictions {
                margin: 1.5rem 1.5rem 0 1.5rem;

                @media only screen and (max-width: $container-md-min-width) {
                    margin: 1.5rem 0 0 0;
                }
            }
        }
    }
}
