@import "../../assets/scss/variables.scss";

.parentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .childContainer {
        display: flex;
        flex-direction: column;
        overflow: visible;
        flex: 1 0;

        .title {
            font-size: 4.25rem;
            margin: 1rem 1rem 0 1rem;
        }

        .description {
            margin: 0 1rem 2rem 1rem;
        }

        .inputsContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            margin-top: 2rem;

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        min-height: 3rem;

        .button {
            min-width: 8rem;
        }
    }
}

.modal {
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 75vw;
    height: 65vh;
    margin: auto;
    max-width: 60rem;

    @media only screen and (max-width: $container-md-min-width) {
        inset: 5px;
        width: auto;
        height: auto;
    }
}
