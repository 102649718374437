@use 'sass:math';
@import "../../../assets/scss/variables.scss";

$shadow: 0 25px 15px 1px #00000052;

.parentContainer {
    height: 100%;
    width: 100%;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
}

.childContainer {
    background: $green;
    width: $login-size;
    height: math.div($login-size, 1.7);
    min-width: 765px;
    min-height: 462px;
    margin-bottom: 2rem;
    border-radius: 0.25rem;
    padding: 1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;

    -moz-box-shadow: 0 5px 15px 1px #00000052;
    -webkit-box-shadow: 0 5px 15px 1px #00000052;
    box-shadow: 0 5px 15px 1px #00000052;

    .heading {
        display: flex;
        position: absolute;
        margin-left: 1rem;

        .title {
            font-size: 10.25rem;
            color: $black;

            .ball {
                width: 5rem;
                height: 5rem;
                border-radius: 5rem;
                display: inline-flex;
                background: $white;
                margin: 0 0.4rem;
            }
        }

        .footballer {
            position: absolute;
            width: 23rem;
            transform: translate(0.5rem, 10.5rem) rotate(300deg);
        }
    }

    @media only screen and (max-width: $container-md-min-width) {
        min-width: $container-sm-min-width;
        background-image: none;
        border-radius: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        align-items: center;

        .heading {
            display: flex;
            flex-direction: column;
            margin-top: 3rem;

            .title {
                font-size: 4rem;
                color: $black;

                .ball {
                    width: 2rem;
                    height: 2rem;
                    border-radius: 2rem;
                    display: inline-flex;
                    margin: 0 0.4rem;
                }
            }

            .footballer {
                position: relative;
                width: 10rem;
                transform: translate(0.5rem, -1.5rem) rotate(300deg);
            }
        }
    }
}
